import store from '@/store'

export default (instance) => {
    instance.interceptors.request.use(
        (config) => {
            store.commit('loader/setTrue');
            return config;
        },
        (error) => {
            store.commit('loader/setFalse');
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            store.commit('loader/setFalse');
            return response;
        },
        (error) => {
            store.commit('loader/setFalse');
            return Promise.reject(error);
        }
    );
}