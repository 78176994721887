<template>
  <div class="row border-top border-bottom mt-3 no-gutters">
    <TheSidebarUserControl
      v-for="control in controls"
      :key="control.text"
      v-bind="{ ...control }"
    >
      <template #default>
        <FeatherIcon :name="control.iconName" class="svg-icon op-7" />
      </template>
      <template #notifications>
        <FeatherIcon :name="control.iconName" class="svg-icon op-7" />
        <span class="badge badge-danger badge-no rounded-circle">5</span>
      </template>
    </TheSidebarUserControl>
  </div>
</template>

<script>
import TheSidebarUserControl from "./TheSidebarUserControl.vue";
import FeatherIcon from "./FeatherIcon.vue";

export default {
  components: {
    TheSidebarUserControl,
    FeatherIcon,
  },
  data() {
    return {
      controls: [
        {
          text: "Notifications",
          iconName: "bell",
          onClick: () => "",
        },
        {
          text: "Settings",
          iconName: "settings",
          onClick: () => "",
        },
        {
          text: "Logout",
          iconName: "log-out",
          onClick: () => this.$store.dispatch("user/logout"),
        },
      ],
    };
  },
};
</script>
