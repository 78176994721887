<template>
  <div
    id="main-wrapper"
    data-theme="light"
    data-layout="vertical"
    data-navbarbg="skin1"
    data-sidebartype="full"
    data-sidebar-position="relative"
    data-header-position="relative"
    data-boxed-layout="full"
    class="min-vh-100">
    <div class="app-container" data-navbarbg="skin1"></div>
    <slot></slot>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";

export default {};
</script>

<style lang="scss" src="@/assets/severny-theme/scss/style.scss"></style>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* PrimeVue */
.p-component,
.p-inputtext {
  font-family: inherit !important;
  font-size: inherit !important;
}

input.p-inputtext {
  height: calc(1.5em + 0.75rem + 2px) !important;
  line-height: 1.5 !important;
}

.form-control .p-dropdown-label {
  padding: initial !important;
  box-shadow: none !important;
}

.form-control .p-dropdown-trigger {
  padding: initial !important;
  width: initial !important;
}</style>