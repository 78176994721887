<template>
  <div class="page-breadcrumb position-static px-3 py-2 rounded-top bg-light">
    <div class="row">
      <div class="col align-self-center">
        <ThePageTitle>{{ $route.name }}</ThePageTitle>
      </div>
    </div>
  </div>
</template>

<script>
import ThePageTitle from "./ThePageTitle.vue";
export default {
  props: {
    title: String,
  },
  components: {
    ThePageTitle,
  },
};
</script>