<template>
  <div class="card">
    <div v-if="$slots['card-header']" :class="['card-header', headerClass]">
      <slot name="card-header"></slot>
    </div>
    <div :class="['card-body', bodyClass]">
      <slot name="card-body"></slot>
    </div>
    <div v-if="$slots['card-footer']" class="card-footer">
      <slot name="card-footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerClass: String,
    bodyClass: String,
    footerClass: String,
  },
};
</script>