<template>
  <CampaignForm>
    <template #default="{ formData }">
      <AppButton @click="submit(formData)" class="btn-block mt-3" disabled>
        Update
      </AppButton>
    </template>
  </CampaignForm>
</template>

<script setup>
import { useRouter } from "vue-router";
import CampaignForm from "@/components/CampaignForm.vue";
import AppButton from "@/components/AppButton.vue";
import axios from "@/api";

const router = useRouter();

function submit(campaign) {
  const adFormatName = campaign.format.name.toLowerCase();

  axios
    .put(`/campaigns/${adFormatName}s/${campaign.uuid}`, campaign, {
      headers: {
        "Content-Type": "application/ld+json"
      }
    })
    .then(() => router.push({ name: 'Campaigns' }));
}
</script>