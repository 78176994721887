<template>
  <div class="list-group-item">
    <p class="lead">{{ name }}</p>
    <slot :name="kebabCase(name)"></slot>
  </div>
</template>

<script>
import { kebabCase } from "lodash-es";
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    kebabCase,
  },
};
</script>