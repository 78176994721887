<template>
  <span class="p-input-icon-left w-100">
    <i :class="`pi pi-${type === 'email' ? 'at' : 'lock'}`"></i>
    <AppInput
      v-bind="{
        type,
        modelValue,
        autocomplete,
        placeholder,
        class: inputClass,
      }"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </span>
</template>

<script>
import AppInput from "./AppInput.vue";
export default {
  components: { AppInput },
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    type: String,
    placeholder: String,
    autocomplete: String,
    inputClass: String,
  },
};
</script>