import $ from "jquery";

export default {
    inserted(el, binding) {
        el.setAttribute("data-toggle", "tooltip");
        el.setAttribute("data-placement", binding.arg);
        el.setAttribute("title", binding.value);

        $(el).tooltip();
    },

    unbind(el) {
        $(el).tooltip('dispose');
    }
}