<template>
  <DataTable
    v-model:selection="selectedCampaigns"
    v-model:filters="filters"
    :value="campaigns"
    :rows="10"
    dataKey="uuid"
    rowHover
    autoLayout
    paginator
    class="p-datatable-campaigns"
    filterDisplay="menu"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    :globalFilterFields="['format.name', 'advertiser', 'name']"
    responsiveLayout="scroll"
    paginatorPosition="both"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center py-3">
        <h5 class="m-0">Campaigns</h5>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="filters.global.value"
            placeholder="Keyword Search"
            class="p-inputtext-sm"
          />
        </span>
      </div>
    </template>
    <template #empty> No campaigns found. </template>
    <template #loading> Loading campaigns data. Please wait. </template>
    <Column
      field="format.name"
      header="Format"
      sortable
      sortField="format.name"
      :showFilterMatchModes="false"
    >
      <template #filter="{ filterModel }">
        <div class="mb-3 font-bold">Ad Formats</div>
        <MultiSelect
          v-model="filterModel.value"
          :options="adFormats"
          optionLabel="name"
          placeholder="Any"
          class="p-column-filter"
        >
          <template #option="slotProps">
            <div>
              <span class="image-text">{{ slotProps.option }}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column
      field="advertiser"
      header="Advertiser"
      sortable
    />
    <Column
      field="name"
      header="Name"
      sortable
    />
    <Column
      field="status"
      header="Status"
      sortable
      :filterMenuStyle="{ width: '14rem' }"
    >
      <template #body="{ data }">
        <CampaignStatusBadge :status="data.status" />
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          placeholder="Any"
          class="p-column-filter"
          :showClear="true"
        >
          <template #value="slotProps">
            <CampaignStatusBadge :status="slotProps.value" />
          </template>
          <template #option="slotProps">
            <CampaignStatusBadge :status="slotProps.option" />
          </template>
        </Dropdown>
      </template>
    </Column>

    <Column
      v-for="pos in ['Start', 'End']"
      :key="pos"
      :field="`date${pos}`"
      :header="`Date  ${pos}`"
      sortable
      dataType="date"
    >
      <template #body="{ data }">
        {{ formatDate(data[`date${pos}`]) }}
      </template>
      <template #filter="{ filterModel }">
        <Calendar
          v-model="filterModel.value"
          dateFormat="mm/dd/yy"
          placeholder="mm/dd/yyyy"
        />
      </template>
    </Column>
    <Column
      :headerStyle="{ 'min-width': '4rem', 'text-align': 'center' }"
      :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }"
    >
      <template #body="{ data }">
        <CampaignsTableActions
          v-bind="{ campaign: data }"
          @campaign-deleted="fetchCampaigns"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import axios from "@/api";
import { parseJSON, format } from "date-fns";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import { FilterMatchMode, FilterOperator } from "primevue/api";

import CampaignStatusBadge from "../components/CampaignStatusBadge.vue";
import CampaignsTableActions from "../components/CampaignsTableActions.vue";

export default {
  components: {
    DataTable,
    Column,
    InputText,
    Dropdown,
    MultiSelect,
    Calendar,
    CampaignStatusBadge,
    CampaignsTableActions,
  },
  data() {
    return {
      campaigns: [],
      selectedCampaigns: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "format.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        advertiser: { value: null, matchMode: FilterMatchMode.IN },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        dateStart: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        dateEnd: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
      },
      statuses: ["active", "inactive"],
      adFormats: ["Brandlift", "Goodeed", "Shoppable Ad"],
    };
  },
  computed: {
    params() {
      return Object.keys(this.filters)
        .map((key) => `${key}=${this.filters[key]}`)
        .join("&");
    },
  },
  mounted() {
    this.fetchCampaigns();
  },
  methods: {
    fetchCampaigns() {
      axios({
        method: "GET",
        url: `/campaigns?${this.params}`,
      })
        .then((response) => {
          this.campaigns = response.data["hydra:member"];
        })
    },
    formatDate(date) {
      return format(parseJSON(date), "dd/MM/yyyy");
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }

  .p-paginator-first {
    border-radius: 6px 0 0 6px;
  }

  .p-paginator-last {
    border-radius: 0 6px 6px 0;
  }
}

:deep(.p-datatable-header) {
  border-radius: 12px 12px 0px 0px !important;
}
</style>