<template>
  <div>
    <TheHeader />
    <TheSidebar />
    <ThePageWrapper>
      <router-view v-show="!isLoading"></router-view>
      <ThePageLoading v-show="isLoading" />
      <ConfirmDialog />
      <Toast />
    </ThePageWrapper>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheSidebar from "@/components/TheSidebar";
import ThePageWrapper from "@/components/ThePageWrapper";
import ThePageLoading from "@/components/ThePageLoading.vue";
import Toast from "@/components/AppToast";

import ConfirmDialog from "primevue/confirmdialog";

import { mapState } from "vuex";

export default {
  components: {
    TheHeader,
    TheSidebar,
    ThePageWrapper,
    ThePageLoading,
    ConfirmDialog,
    Toast,
  },
  computed: {
    ...mapState("loader", ["isLoading"]),
  },
};
</script>