<template>
  <nav class="sidebar-nav h-100">
    <ul
      id="sidebarnav"
      class="in bg-white h-100">
      <li class="nav-small-cap">
        <span class="hide-menu">Menu</span>
      </li>
      <router-link
        v-for="route in navLinks"
        :key="route.name"
        :to="{ name: route.name }"
        custom
        v-slot="{ navigate }">
        <li
          :active="isActive(route)"
          :class="['sidebar-item d-flex', isActive(route) && 'selected']">
          <span
            v-show="isActive(route)"
            class="rounded-right bg-primary"
            style="width: 6px"></span>
          <a
            :class="[
              'sidebar-link sidebar-link btn w-100',
              isActive(route) && 'text-primary',
            ]"
            @click="navigate">
            <FeatherIcon :name="route.meta.icon" />
            <span class="hide-menu">{{ route.name }}</span>
          </a>
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'
import FeatherIcon from "./FeatherIcon.vue";
export default defineComponent({
  components: { FeatherIcon },
  data() {
    return {
      navLinks: [],
    };
  },
  methods: {
    isActive(route) {
      const currentRoute = this.$router.currentRoute.value;
      return currentRoute.fullPath.includes(route.path) ? true : false;
    }
  },
  mounted() {
    const routes = this.$router.options.routes.find((r) => r.path === "/app").children;

    this.navLinks = routes.filter(
      (route) => route.meta && route.meta.isNavLink
    );
  },
});
</script>