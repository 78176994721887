import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// PrimeVue
import PrimeVue from 'primevue/config';
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primevue/resources/themes/tailwind-light/theme.css"
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue, { ripple: true })
    .use(ConfirmationService)
    .use(ToastService)
    .mount('#app');