<template>
  <aside class="left-sidebar user-select-none bg-brand" data-sidebarbg="skin6">
    <TheSidebarUserPanel />
    <div class="scroll-sidebar overflow-auto" data-sidebarbg="skin6">
      <TheSidebarNav />
    </div>
  </aside>
</template>

<script>
import TheSidebarNav from "@/components/TheSidebarNav";
import TheSidebarUserPanel from "@/components/TheSidebarUserPanel.vue";

export default {
  components: {
    TheSidebarNav,
    TheSidebarUserPanel,
  },
};
</script>
