<template>
  <header class="topbar" data-navbarbg="skin1">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
      <div class="navbar-header">
        <a
          class="nav-toggler waves-effect waves-light d-block d-md-none"
          href="javascript:void(0)"
          ><i class="ti-menu ti-close"></i
        ></a>

        <div class="navbar-brand justify-content-center">
          <img
            src="@/assets/logo_blanc.svg"
            height="75"
            width="75"
            alt="TF1 PUB"
          />
        </div>

        <a
          class="topbartoggler d-block d-md-none waves-effect waves-light"
          href="javascript:void(0)"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ><i class="ti-more"></i
        ></a>
      </div>

      <div
        class="navbar-collapse collapse"
        id="navbarSupportedContent"
        data-navbarbg="skin1"
      >
        <TheBreadcrumb class="w-100" />
        <!-- <ul class="navbar-nav w-100 align-items-center">
          <li class="nav-item ml-0 ml-md-3 ml-lg-0">
            <a class="nav-link search-bar" href="javascript:void(0)">
              <form class="my-2 my-lg-0">
                <div class="customize-input customize-input-v4">
                  <input
                    class="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <i class="form-control-icon" data-feather="search"></i>
                </div>
              </form>
            </a>
          </li>
        </ul> -->
      </div>
    </nav>
  </header>
</template>

<script>
import TheBreadcrumb from "./TheBreadcrumb.vue";
export default {
  components: { TheBreadcrumb },
};
</script>

<style scoped>
.topbar {
  border-top: 5px solid;
  border-image: linear-gradient(to right, #8a2387, #e94057, #f27121) 3;
}
</style>