import axios from '@/api';

const state = () => ({
    all: null
})

const getters = {
    getTargetGroupBy: (state) => (key, value) => {
        return state.all.find(group => group[key] === value);
    }
}

// actions
const actions = {
    getAllTargetGroups({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("/target_groups").then((res) => {
                commit('setGroups', res.data["hydra:member"]);
                resolve(res.data["hydra:member"]);
            }).catch((err) => {
                reject(err);
            })
        })
    },

    createTargetGroup({ dispatch }, targetGroup) {
        return new Promise((resolve, reject) => {
            axios.post("/target_groups", targetGroup)
                .then(({ data }) => {
                    dispatch('getAllTargetGroups');
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
}

// mutations
const mutations = {
    setGroups(state, groups) {
        state.all = groups;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}