<template>
  <div class="page-wrapper d-flex flex-column">
    <ThePageBreadcrumb />
    <div
      class="container-fluid flex-grow-1 d-flex flex-column pb-5 overflow-auto"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ThePageBreadcrumb from "./ThePageBreadcrumb.vue";
import initTheme from "../assets/severny-theme/dist/js/custom.js";

export default {
  components: {
    ThePageBreadcrumb,
  },
  mounted() {
    initTheme();
  },
};
</script>