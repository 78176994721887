<template>
  <ListData :data="users" />
</template>

<script>
import axios from "@/api";
import ListData from "../components/ListData.vue";

export default {
  components: { ListData },
  data() {
    return {
      users: [],
    };
  },
  mounted() {
    axios.get("/users").then((res) => (this.users = res.data["hydra:member"]));
  },
};
</script>