<template>
  <div class="p-3 mb-3 rounded bg-white text-dark">
    <span class="d-flex justify-content-between pb-2">
      <h3>Campaign info </h3>

      <SubmitButtonAxios method="GET" :url="`/campaigns/${campaign.uuid}/deliver_creatives`" class="btn-warning">
        <i class="fas fa-truck-loading mr-1"></i>
        Deliver creatives
      </SubmitButtonAxios>
    </span>
    <ListGroup class-name="list-group-flush bg-primary" :data="campaign"
      :keys="[{ name: 'format', path: 'format.name' }, 'advertiser', 'name']" />
  </div>
</template>

<script>
import ListGroup from "./ListGroup.vue";
import SubmitButtonAxios from "./SubmitButtonAxios.vue";

export default {
  components: { ListGroup, SubmitButtonAxios },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
};
</script>