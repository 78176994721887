import axios from 'axios';
import auth from './interceptors/auth'
import loading from './interceptors/loading'

const config = {
    instance: axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
        ...(process.env.NODE_ENV === 'development' && {
            params: {
                XDEBUG_SESSION_START: "PHPSTORM"
            }
        })
    }),
    interceptors: [
        auth,
        loading
    ]
}

config.interceptors.forEach(interceptor => {
    interceptor(config.instance);
})

export default config.instance;
