<template>
  <AppBadge :class="[className[status] || '', 'text-uppercase']">
    {{ status }}
  </AppBadge>
</template>

<script>
import AppBadge from "./AppBadge.vue";
export default {
  components: { AppBadge },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      className: {
        active: "badge-success",
        inactive: "badge-danger",
      },
    };
  },
};
</script>
