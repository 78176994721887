<template>
  <AppForm class="container mt-4">
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="form-group">
          <AuthFormField
            v-model="credentials.email"
            type="email"
            placeholder="Email"
            autocomplete="username"
          />

          <AuthFormField
            v-model="credentials.password"
            type="password"
            placeholder="Password"
            autocomplete="current-password"
          />

          <AuthFormSubmit
            v-bind="{ ...credentials }"
            text="Continue"
            class="mt-3"
            @auth-error="(err) => (errorMessage = err)"
          />

          <AppAlert
            v-if="errorMessage.length"
            :text="errorMessage"
            class="alert-danger mt-2"
          />
        </div>

        <Divider align="center" class="text-muted">or</Divider>

        <div class="form-group">
          <AuthFormSocial />
        </div>
      </div>
    </div>
  </AppForm>
</template>

<script>
import AppForm from "./AppForm.vue";
import AuthFormField from "./AuthFormField.vue";
import AuthFormSocial from "./AuthFormSocial.vue";
import AuthFormSubmit from "./AuthFormSubmit.vue";
import AppAlert from "./AppAlert.vue";

import Divider from "primevue/divider";

export default {
  components: {
    AppForm,
    AuthFormField,
    AuthFormSubmit,
    AuthFormSocial,
    AppAlert,
    Divider,
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      errorMessage: "",
    };
  },
};
</script>

<style scoped>
:deep(.p-input-icon-left:focus-within) {
  z-index: 1;
}
:deep(input[type="email"]) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -2px;
}
:deep(input[type="password"]) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>