<template>
  <div id="the-page-loading" class="h-100 w-100">
    <div class="spinner-border text-primary my-auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<style scoped>
#the-page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-border {
  height: 100px;
  width: 100px;
}
</style>