<template>
  <div class="form-group row">
    <label v-if="label" class="col-sm-2 col-form-label">
      <slot name="label"> {{ label }}</slot>
    </label>
    <div class="col-sm-10">
      <slot>
        <AppInput
          v-bind="{ ...$attrs, ...$props, modelValue }"
          @input="$emit('update:modelValue', $event.target.value)"
        />
      </slot>
    </div>
  </div>
</template>
  
<script>
import AppInput from "./AppInput.vue";
export default {
  inheritAttrs: false,
  emits: ["update:modelValue"],
  components: { AppInput },
  props: ["modelValue", "label"],
};
</script>