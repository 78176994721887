<template>
  <div class="user-profile text-center pt-2 text-white">
    <div class="d-flex align-items-center justify-content-center pb-3">
      <button
        class="btn profile-pic rounded-circle position-relative"
        type="button"
      ></button>
    </div>
    <div class="profile-section">
      <p class="font-weight-light mb-0 font-18">
        {{ user.firstName }} <br />
        {{ user.lastName }}
      </p>
      <span class="op-7 font-14"></span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("user", ["user"]),
  },
};
</script>