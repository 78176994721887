<template>
  <SubmitButton v-bind="{ isLoading, text }" @click="submit">
    <slot></slot>
  </SubmitButton>
</template>

<script>
import axios from "@/api";
import SubmitButton from "./SubmitButton.vue";

export default {
  components: { SubmitButton },
  emits: ["response", "error"],
  props: {
    text: String,
    url: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
    method: {
      type: String,
      required: true,
      default: () => "POST",
      validator: function (value) {
        // The value must match one of these strings
        return ["GET", "POST", "PUT", "PATCH", "DELETE"]
          .map((method) => method.toLowerCase())
          .includes(value.toLowerCase());
      },
    },
    finallyCallback: Function,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    submit() {
      this.isLoading = true;
      console.log(this.data);
      axios({
        method: this.method,
        url: this.url,
        data: this.data,
      })
        .then((response) => {
          this.$emit("response", response);
        })
        .catch((error) => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.isLoading = false;
          this.finallyCallback && this.finallyCallback();
        });
    },
  },
};
</script>