<template>
  <ThemeWrapper>
    <router-view></router-view>
  </ThemeWrapper>
</template>

<script>
import ThemeWrapper from "@/components/ThemeWrapper";

export default {
  name: "App",
  components: {
    ThemeWrapper,
  },
};
</script>