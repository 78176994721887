import { reactive } from 'vue'
import { Brandlift } from '@/interfaces/Brandlift';


const initialState: Brandlift = {
    campaign: {
        odpNumber: "",
        format: "/formats/1",
        advertiser: "",
        name: "",
        targetGroups: [],
        dateStart: undefined,
        dateEnd: undefined
    },
    questions: [
        {
            "ordinal": 1,
            "title": "Parmi les propositions suivantes, pour lesquelles avez-vous vu de la publicité vidéo en ligne au cours du dernier mois ?",
            "choices": [],
            "isSingleAnswer": false,
            "isShuffledAnswers": true
        },
        {
            "ordinal": 2,
            "title": "Si vous deviez prochainement choisir une marque de [univers] la ou lesquelles choisiriez-vous ?",
            "choices": [],
            "isSingleAnswer": false,
            "isShuffledAnswers": true
        }
    ],
    studiedBrand: "",
    respondentGoal: 500,
    isInternalStudy: false
};

const formData: Brandlift = reactive<Brandlift>({ ...initialState });

export function useCampaign() {
    function resetCampaignData() {
        Object.assign(formData, initialState);
        formData.campaign.odpNumber = ""
        formData.campaign.format = "/formats/1"
        formData.campaign.advertiser = ""
        formData.campaign.name = ""
        formData.campaign.dateStart = undefined
        formData.campaign.dateEnd = undefined


        formData.campaign.targetGroups.length = 0;
        if (formData.questions?.length) {
            formData.questions.forEach(q => {
                q.choices.length = 0;
            });
        }
    }

    function setAdFormat(adFormatId: number) {
        initialState.campaign.format = `/formats/${adFormatId}`
    }

    return { formData, setAdFormat, resetCampaignData }
}