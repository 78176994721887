<template>
  <div class="list-group">
    <span class="h3"> <i class="fas fa-cog"></i> Settings </span>
    <AdFormatSettingsPageSection
      v-for="section in ['Name', 'Icon', 'Mailing List']"
      :key="section"
      :name="section"
    >
      <template #name>
        <AppInput v-model="formData.name" />
      </template>

      <template #icon>
        <input type="color" v-model="formData.backgroundColor" class="mb-1" />
        <div
          class="d-flex justify-content-center align-items-center p-4"
          :style="'background-color:' + formData.backgroundColor"
        >
          <img :src="adFormat.iconUrl" height="50px" alt="" />
        </div>
      </template>

      <template #mailing-list>
        <ul>
          <li v-for="email in formData.mailingList.emails" :key="email.address">
            <AppInput v-model="email.address" class="mb-1" />
          </li>
        </ul>
      </template>
    </AdFormatSettingsPageSection>

    <SubmitButtonAxios
      method="PUT"
      :url="`/formats/${adFormat.id}`"
      :data="formData"
      text="Save"
      class="mt-3"
      @response="$router.push({ name: 'Creative' })"
    />
  </div>
</template>

<script>
import AppInput from "../components/AppInput.vue";
import AdFormatSettingsPageSection from "../components/AdFormatSettingsPageSection.vue";
import SubmitButtonAxios from "../components/SubmitButtonAxios.vue";

export default {
  components: { AdFormatSettingsPageSection, AppInput, SubmitButtonAxios },
  props: {
    adFormat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        ...this.adFormat,
      },
    };
  },
  beforeMount() {
    console.log(this.adFormat);
    if (!this.adFormat) this.$router.push({ name: "Creative" });
  },
};
</script>