<template>
  <TagInput
    :modelValue="modelValue"
    :suggestions="targetGroups"
    :displayed-key="['name']"
    :transform="transform"
    item-class="badge-primary rounded-pill"
    @update:modelValue="onUpdate"
    @item-add="$emit('item-add', $event)"
  >
    <template #item="{ item }">
      <i class="fas fa-user-tag"></i><span class="ml-2">{{ item.name ?? item }}</span>
    </template>
  </TagInput>
</template>

<script>
import TagInput from "./TagInput.vue";

export default {
  components: {
    TagInput,
  },
  emits: ["update:modelValue", "item-add"],
  props: {
    modelValue: Array,
  },
  data() {
    return {
      targetGroups: [],
    };
  },
  mounted() {
    this.$store
      .dispatch("targetGroups/getAllTargetGroups")
      .then((targetGroups) => {
        this.targetGroups = targetGroups;
      });
  },
  methods: {
    onUpdate($event) {
      this.$emit('update:modelValue', $event)
    },

    transform(targetGroup) {
      const isIdentifierIRI = () => new RegExp(/^\/target_groups\/\d+/).test(targetGroup);

      switch (true) {
        case typeof targetGroup === "string" && isIdentifierIRI():
          return targetGroup;

        case typeof targetGroup === "string":
          return { name: targetGroup }

        case Boolean(targetGroup["@id"]):
          return targetGroup["@id"];

        default:
          return targetGroup;
      }
    }
  },

  createTargetGroupOnServer(name) {
    return this.$store.dispatch(
      "targetGroups/createTargetGroup", { name }
    );
  },

  onItemSelect(event) {
    this.$emit("update:modelValue", event);
  },
};
</script>


<!-- <script setup lang="ts">
import { onMounted, defineProps, defineEmits } from "vue";
import TagInput from "./TagInput.vue";
import store from "@/store"
import { TargetGroup } from "@/interfaces/Campaign";

const emit = defineEmits<{
  (event: 'update:modelValue', value: Array<TargetGroup>): void
  (event: 'item-add', item: TargetGroup): void
}>();

const props = defineProps<{
  modelValue: Array<TargetGroup>
}>();

let targetGroups: TargetGroup[];

onMounted(() => {
  store
    .dispatch("targetGroups/getAllTargetGroups")
    .then((data) => {
      targetGroups = data;
    });
});

function onUpdate(event: Array<TargetGroup>) {
  emit('update:modelValue', event)
}

function transform(targetGroup: TargetGroup) {
  return targetGroup["@id"] ? targetGroup["@id"] : targetGroup;
}
</script> -->