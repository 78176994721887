<template>
  <div>
    <CampaignFormHeader v-if="campaign"></CampaignFormHeader>
    <AppForm>
      <div class="pt-4 p-3 bg-white rounded pb-3">
        <CampaignFields v-bind="{ adFormatId }" />
        <component
          :is="fieldsComponent"
          v-bind="{ targetGroups, campaign }"
        />
      </div>
      <slot v-bind="{ formData: campaign }">
        <AppButton
          class="btn-block mt-4"
          @click="submit()"
        >
          Create
        </AppButton>
      </slot>
    </AppForm>
  </div>
</template>
 
<!-- <script setup>
import { useCampaign } from "@/composables/campaign";

const { formData: campaign } = useCampaign();
</script> -->

<script>
import { defineAsyncComponent } from "vue";
import { useCampaign } from "@/composables/campaign";
import { upperFirst, camelCase, assignIn } from "lodash-es";
import axios from "@/api"
import AppForm from "./AppForm.vue";
import CampaignFormHeader from "./CampaignFormHeader.vue";
import CampaignFields from "../components/CreativeForm/CampaignFields.vue";
import AppButton from "../components/AppButton.vue";

export default {
  components: {
    CampaignFields,
    CampaignFormHeader,
    AppButton,
    AppForm,
  },
  setup() {
    const { formData, resetCampaignData } = useCampaign();
    const campaign = formData.campaign;
    return { campaign, resetCampaignData };
  },
  computed: {
    fieldsComponent() {
      const campaign = this.campaign;
      if (!campaign.format?.name) {
        return null;
      }
      const componentName = upperFirst(camelCase(campaign.format.name)) + "Fields";

      const component = defineAsyncComponent(() =>
        import(`@/components/CreativeForm/${componentName}.vue`)
      );

      return component;
    },
    adFormatId() {
      return this.campaign?.format?.['@id'];
    },
    targetGroups() {
      const campaign = this.campaign;
      return campaign ? campaign.targetGroups : [];
    },
  },
  methods: {
    upperFirst,
  },
  mounted() {
    const campaignUuid = this.$route.params.id
    if (campaignUuid) {
      axios
        .get(`/campaigns/${campaignUuid}`)
        .then(res => {
          assignIn(this.campaign, res.data)
        })
    }
  },
  unmounted() {
    this.resetCampaignData();
  }
};
</script>