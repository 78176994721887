import { createStore } from 'vuex'

import loader from './modules/loader'
import user from './modules/user'
import formats from './modules/formats'
import targetGroups from './modules/targetGroups'

export default createStore({
  modules: {
    loader,
    user,
    formats,
    targetGroups
  }
})
