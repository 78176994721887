<template>
  <div
    class="
      container-fluid
      d-flex
      flex-column
      justify-content-center
      align-items-center
      text-white
      p-5
    "
  >
    <h1>404</h1>
    <h1>PAGE NOT FOUND</h1>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>