<template>
  <i :data-feather="name" class="feather-icon"></i>
</template>

<script>
import feather from "feather-icons";
export default {
  props: {
    name: String,
  },
  mounted() {
    feather.replace();
  },
};
</script>