<template>
  <AppButton class="btn-primary" :disabled="isLoading">
    <span v-show="!isLoading">
      <slot>{{ text }}</slot>
    </span>
    <LoadingSpinner v-show="isLoading" class="spinner-border-sm" />
  </AppButton>
</template>

<script>
import AppButton from "./AppButton.vue";
import LoadingSpinner from "./LoadingSpinner.vue";
export default {
  components: { AppButton, LoadingSpinner },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    text: String,
  },
};
</script>