<template>
  <a
    class="btn btn-light flex-grow-1 d-flex align-items-center mr-2 mb-2 border"
    :href="`${OAuthPath}/connect/${provider}`"
  >
    <img
      :src="require(`@/assets/signin-assets/${provider}.svg`)"
      height="20"
      alt=""
      class="m-auto"
    />
    <!-- <span class="ml-3">Sign in with {{ provider }}</span> -->
  </a>
</template>

<script>
export default {
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      OAuthPath: process.env.VUE_APP_API_URL,
    };
  },
};
</script>