<template>
  <ul
    multiple
    class="
      autocomplete
      position-absolute
      bg-white
      mt-1
      rounded
      bg-white
      mx-n1
      border
      overflow-auto
    "
  >
    <li
      v-for="suggestion in suggestions"
      v-ripple
      :key="suggestion.refIndex"
      :value="suggestion.item"
      @click="onSelect(suggestion.refIndex)"
      @mousedown.prevent
      class="my-auto p-3 p-ripple"
    >
      {{ suggestion.item[keys] }}
    </li>
  </ul>
</template>

<script>
import Fuse from "fuse.js";
import Ripple from "primevue/ripple";

export default {
  directives: {
    ripple: Ripple,
  },
  emits: ["select", "update:modelValue"],
  props: {
    searchQuery: String,
    list: Array,
    keys: Array,
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    suggestions() {
      const fuse = this.fuse;
      fuse.setCollection(this.list);
      return fuse.search(this.searchQuery);
    },
  },
  methods: {
    onSelect(index) {
      this.selected = this.list[index];
      this.$emit("update:modelValue", this.selected);
      this.$emit("select", this.selected);
    },
  },
  beforeMount() {
    const options = {
      keys: this.keys,
    };
    this.fuse = new Fuse(this.list, options);
  },
};
</script>

<style scoped>
.autocomplete {
  left: 0;
  top: 100%;
  right: 0;
  z-index: 999;
  max-height: 215px;
}

li:hover {
  cursor: pointer;
  background: #f4f4f5;
}
</style>