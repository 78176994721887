<template>
  <span class="badge">
    <slot>{{ text }}</slot>
  </span>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>
