<template>
  <div v-if="data">
    <CreativeFormHeader :ad-format="data.campaign.format">
      <div class="h4 pl-2 my-auto d-flex align-items-center">
        {{ data.campaign.format.name }}:
        <span class="ml-2">
          {{ data.campaign.advertiser }} - {{ data.campaign.name }}
        </span>
      </div>
    </CreativeFormHeader>
    <CampaignInfo :campaign="data.campaign" />

    <component
      :is="component"
      v-bind="{ data }"
    />

  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import axios from "@/api";
import { upperFirst, camelCase } from "lodash-es";

import Card from "@/components/AppCard.vue";
import AppBadge from "@/components/AppBadge.vue";
import CreativeFormHeader from "../components/CampaignFormHeader.vue";
import CampaignInfo from "../components/CampaignInfo.vue";

export default {
  components: {
    Card,
    AppBadge,
    CreativeFormHeader,
    CampaignInfo,
  },
  data() {
    return {
      data: null,
      contentToExport: null,
    };
  },
  computed: {
    component: function () {
      const componentName = upperFirst(camelCase(this.$route.params["adFormat"])) + "Results";

      const component = defineAsyncComponent(() =>
        import(`@/components/CampaignResults/${componentName}.vue`)
      )

      return component;
    },
  },

  mounted() {
    axios
      .get(this.$route.path.replace("/app", ""))
      .then(res => this.data = res.data)
  },
};
</script>