<template>
  <TheSidebarUserProfile>
    <TheSidebarUserControls />
  </TheSidebarUserProfile>
</template>

<script>
import TheSidebarUserProfile from "./TheSidebarUserProfile.vue";
import TheSidebarUserControls from "./TheSidebarUserControls.vue";

export default {
  components: {
    TheSidebarUserProfile,
    TheSidebarUserControls,
  },
};
</script>