<template>
  <div class="col-4 border-right">
    <button
      @click="onClick"
      v-tooltip:bottom="text"
      class="btn btn-block p-3 d-block menubar-height text-white"
      data-display="static"
      role="button"
    >
      <slot :name="slotName ? slotName : text.toLowerCase()">
        <slot></slot>
      </slot>
    </button>
  </div>
</template>

<script>
import TooltipDirective from "@/directives/TooltipDirective.js";

export default {
  directives: {
    tooltip: TooltipDirective,
  },
  props: {
    slotName: String,
    text: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
};
</script>