<template>
  <!-- <nav aria-label="breadcrumb">
    <ol class="breadcrumb m-0 p-0">
      <li
        v-for="subpath in tail($route.path.split('/'))"
        :key="subpath"
        class="breadcrumb-item text-light"
      >
        {{ uuidValidate(subpath) ? subpath : startCase(decodeURI(subpath)) }}
      </li>
    </ol>
  </nav> -->
  <Breadcrumb :home="home" :model="items" class="text-capitalize" />
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";
import { tail, startCase } from "lodash-es";
import { validate as uuidValidate } from "uuid";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      home: { icon: "fas fa-th", to: "/app" },
    };
  },
  computed: {
    items() {
      return tail(this.$route.path.split("/")).map((p) => ({ label: p }));
    },
  },
  methods: {
    tail,
    startCase,
    uuidValidate,
  },
};
</script>

<style scoped>
/* .breadcrumb-item::before {
  font-size: inherit !important;
} */
</style>