<template>
  <div>
    <SplitButton
      label="details"
      :model="[
        {
          label: 'Results',
          icon: 'pi pi-chart-bar',
          command: () => {
            goToResults();
          },
        },
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            goToEdit();
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-times-circle',
          command: () => {
            showDeleteModal();
          },
        },
      ]"
      class="p-button-sm"
      @click="goToResults()"
    />
  </div>
</template>

<script>
import SplitButton from "primevue/splitbutton";
import axios from "@/api";
import { snakeCase } from "lodash-es";

export default {
  emits: ["campaign-deleted"],
  components: {
    SplitButton,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEditModal: false,
    };
  },
  computed: {
    campaignDescription() {
      const campaign = this.campaign;
      return `[${campaign.format.name}] ${campaign.advertiser} - ${campaign.name}`
    }
  },
  methods: {
    snakeCase,
    goToResults() {
      this.$router.push({
        name: "Campaign results",
        params: {
          adFormat: snakeCase(this.campaign.format.name),
          id: this.campaign.uuid,
        },
      });
    },
    goToEdit() {
      this.$router.push({
        name: "Campaign edit",
        params: {
          id: this.campaign.uuid,
          adFormatName: this.campaign.format.name
        },
      });
    },
    showDeleteModal() {
      this.$confirm.require({
        message: "Are you sure you want to delete: \n" + this.campaignDescription,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "btn-danger p-button-danger",
        accept: () => {
          //callback to execute when user confirms the action
          this.deleteCampaign();
        },
      });
    },
    deleteCampaign() {
      axios.delete(`/campaigns/${this.campaign.uuid}`).then(() => {
        this.$emit("campaign-deleted");
        this.notifyCampaignDeletion();
      });
    },
    notifyCampaignDeletion() {
      this.$toast.add({
        severity: "info",
        summary: "Campaign deleted",
        detail: `Deleted campaign ${this.campaignDescription}`,
        life: 3000,
      });
    }
  },
};
</script>