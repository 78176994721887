const state = () => ({
    isLoading: false
})

// getters
const getters = {

}

// actions
const actions = {

}

// mutations
const mutations = {
    setTrue(state) {
        state.isLoading = true;
    },
    setFalse(state) {
        state.isLoading = false;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}