<template>
  <SubmitButton
    class="btn-block"
    :text="text"
    :is-loading="isLoading"
    @click="authenticate"
  />
</template>

<script>
import SubmitButton from "./SubmitButton.vue";
export default {
  components: { SubmitButton },
  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    authenticate() {
      this.isLoading = true;
      this.$store
        .dispatch("user/authenticate", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push("/app");
        })
        .catch((err) => {
          this.$emit(
            "auth-error",
            err.response.data.message || err.response.data.error
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>