<template>
  <div>
    <div class="h4 text-left">
      <slot />
    </div>
    <ColorfulHeader class="mb-2" />
  </div>
</template>

<script>
import ColorfulHeader from "./ColorfulHeader.vue";
export default {
  components: { ColorfulHeader },
};
</script>