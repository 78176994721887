<template>
  <div>
    <CampaignFormHeader>{{ adFormatName }}</CampaignFormHeader>
    <form
      @submit.prevent
      @keydown.enter.prevent
    >
      <div class="pt-4 p-3 bg-white rounded pb-3">
        <CampaignFields v-bind="{ adFormatId }" />
        <component :is="fieldsComponent" />
      </div>
      <AppButton
        @click="submit"
        class="btn-block mt-4"
      >
        Create
      </AppButton>
    </form>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { useCampaign } from "@/composables/campaign";

import { upperFirst, camelCase } from "lodash-es";
import axios from "@/api";
import CampaignFormHeader from "../components/CampaignFormHeader.vue";
import CampaignFields from "../components/CreativeForm/CampaignFields.vue";
import AppButton from "../components/AppButton.vue";


export default {
  components: {
    CampaignFields,
    CampaignFormHeader,
    AppButton,
  },

  setup() {
    const { formData, setAdFormat, resetCampaignData } = useCampaign();
    return { formData, setAdFormat, resetCampaignData };
  },

  props: {
    adFormatId: {
      type: String,
      required: true,
    },
    adFormatName: {
      type: String,
      required: true,
    },
  },

  computed: {
    fieldsComponent() {
      const componentName = upperFirst(camelCase(this.adFormatName)) + "Fields";

      const component = defineAsyncComponent(() =>
        import(`@/components/CreativeForm/${componentName}.vue`)
      );
      return component;
    },

    targetGroups() {
      const campaign = this.formData.campaign;
      return campaign ? campaign.targetGroups : [];
    },
  },

  methods: {
    upperFirst,
    submit() {
      this.$toast.removeAllGroups();
      const url = `/campaigns/${this.$route.params.name}s`;

      axios
        .post(url, this.formData)
        .then(() => {
          this.$router.push({
            name: "Campaigns",
            params: {
              success: true
            }
          });

          this.notifyCampaignCreated();
        })
        .catch(err => {
          this.$toast.add({
            severity: "error",
            summary: err.response.data["hydra:title"],
            detail: err.response.data["hydra:description"]
          });
        })
    },

    notifyCampaignCreated() {
      this.$toast.add({
        severity: "success",
        summary: "Campaign created",
        // detail: `Created campaign [${campaign.format.name}] ${campaign.advertiser} - ${campaign.name}`,
        life: 3000,
      });
    }
  },

  mounted() {
    this.resetCampaignData();
    this.setAdFormat(this.adFormatId);
  },

  beforeRouteEnter(to, from, next) {
    to.params.adFormatName ? next() : next("/");
  },

  beforeRouteLeave(to, from, next) {
    const isConfirmationRequired = !to.params.success;

    const confirm = () => window.confirm(
      "Do you really want to leave? you have unsaved changes"
    );

    if (!isConfirmationRequired || confirm()) {
      next();
    } else {
      next(false);
    }
  }
};
</script>