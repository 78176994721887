<template>
  <AuthLayout>
    <AuthForm />
  </AuthLayout>
</template>

<script>
import { mapState } from "vuex";
import AuthForm from "../components/AuthForm.vue";
import AuthLayout from "../components/AuthLayout.vue";

export default {
  components: { AuthLayout, AuthForm },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  beforeRouteLeave(to, from, next) {
    !this.isAuthenticated ? next(false) : next();
  },
};
</script>