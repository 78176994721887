<template>
  <div class="row text-center">
    <AuthFormSocialButton
      v-for="provider in providers"
      v-bind="{ provider }"
      :key="provider"
    />
  </div>
</template>

<script>
import AuthFormSocialButton from "./AuthFormSocialButton.vue";
export default {
  components: { AuthFormSocialButton },
  data() {
    return {
      OAuthPath: process.env.VUE_APP_API_URL,
      providers: ["google", "microsoft"],
    };
  },
};
</script>