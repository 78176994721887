<template>
  <div
    class="d-flex no-block justify-content-center align-items-center vh-100"
    :style="`background: url(${require('@/assets/severny-theme/assets/images/big/auth-bg.jpg')}) no-repeat center center`"
  >
    <div class="row">
      <div
        class="
          col-lg-7 col-md-5
          d-flex
          align-items-center
          justify-content-center
          bg-brand
        "
      >
        <img
          :src="require('@/assets/images/tf1live-creation-digitale.png')"
          width="500"
        />
      </div>
      <div class="col-lg-5 col-md-7 bg-white py-2">
        <div class="px-2">
          <div class="text-center">
            <img
              :src="require('@/assets/tf1pub_blue.svg')"
              alt=""
              height="100"
            />
          </div>
          <h3 class="mt-2 text-center text-dark">Sign in</h3>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>