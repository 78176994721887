<template>
  <div>
    <input v-model="searchQuery" type="text" class="form-control w-100 mb-2" />
    <button class="btn btn-success float-right mb-1">+ Add</button>

    <ul class="list-group w-100">
      <li
        v-for="(targetGroup, groupIndex) in filteredGroups"
        :key="groupIndex"
        class="
          list-group-item
          d-flex
          justify-content-between
          align-items-center
        "
      >
        {{ targetGroup.name || targetGroup.item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Fuse from "fuse.js";

export default {
  data() {
    return {
      fuse: new Fuse(this.targetGroups, {
        keys: ["name"],
      }),
      searchQuery: "",
    };
  },
  computed: {
    ...mapState({
      targetGroups: (state) => state.targetGroups.all,
    }),
    filteredGroups: function () {
      if (!this.targetGroups) {
        return;
      }
      this.fuse.setCollection(this.targetGroups);
      return this.searchQuery
        ? this.fuse.search(this.searchQuery)
        : this.targetGroups;
    },
  },
  beforeMount() {
    if (!this.targetGroups) {
      this.$store.dispatch("targetGroups/getAllTargetGroups");
    }
  },
};
</script>