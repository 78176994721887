<template>
  <div>
    <div class="row" v-for="key in keys" v-bind="$attrs" :key="key">
      <div class="col-1 text-muted">
        <slot :name="`key-${key.name || key}`">{{ key.name || key }}</slot>
      </div>
      <div class="col-1">
        <slot :name="`value-${key.name || key}`">
          <div class="h4">
            <span class="badge badge-light mb-2">
              {{ get(data, key.path || key) }}
            </span>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "lodash-es";
export default {
  inheritAttrs: false,
  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
    keys: {
      type: Array,
      required: true,
    },
    className: String,
  },
  methods: {
    get,
  },
};
</script>