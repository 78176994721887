<template>
  <div
    @click="focus"
    class="tag-input p-inputtext form-control position-relative d-flex flex-wrap align-items-center p-0 m-0"
    :class="selectedTags.length && 'h-auto'"
    style="cursor: text"
  >
    <ul class="d-flex flex-wrap align-items-center h-auto">
      <li
        v-for="(item, itemIndex) in selectedTags"
        :key="item.name"
        class="d-flex align-items-center h-100"
      >
        <AppChip
          :text="item"
          :class="itemClass"
          @remove="removeTag(itemIndex)"
        >
          <slot
            name="item"
            v-bind="{ item, itemIndex }"
          >
            {{ item }}
          </slot>
        </AppChip>
      </li>
      <li>
        <input
          v-model.trim="entry"
          autocomplete="off"
          type="text"
          class="border-0 ml-2"
          @input="onInput"
          @keydown="characterLimit && handlecharacterLimit($event)"
          @keydown.enter="onEnter"
          @keydown.delete="removeLastTag"
          @keydown.space="handleWhitespace"
          @focusout="onFocusOut"
          @paste="onPaste"
        />
      </li>
    </ul>

    <AppAutocomplete
      v-if="suggestions"
      v-show="isTyping || (isFocused && !isEmpty(entry))"
      :list="without(suggestions, ...selectedTags)"
      :search-query="entry"
      :keys="displayedKey"
      @select="onSelect"
    />
  </div>
</template>

<script>
import AppChip from "./AppChip.vue";
import AppAutocomplete from "./AppAutocomplete.vue";
import { without, isEmpty, isEqual } from "lodash-es";
import $ from 'jquery'

export default {
  components: {
    AppAutocomplete,
    AppChip
  },

  emits: [
    "update:modelValue",
    "item-select",
    "item-add",
    "item-remove"
  ],

  props: {
    modelValue: Array,
    suggestions: Array,
    displayedKey: [String, Array],
    transform: Function,
    itemClass: String,
    characterLimit: [Number, null]
  },

  data() {
    return {
      isFocused: false,
      isTyping: false,
      entry: "",
      selectedTags: [],
      value: [],
      htmlInputElement: null
    };
  },

  watch: {
    modelValue: {
      handler(newValue) {
        !isEqual(newValue, this.selectedTags.map(this.transform)) && (this.selectedTags = newValue);
        if (this.selectedTags.length) {
          this.transform && (this.value = newValue.map(this.transform));
        }
      }
    }
  },

  methods: {
    without,
    isEmpty,

    pushTag(value) {
      this.selectedTags.push(value);
      this.transform && this.value.push(this.transform(value));
      this.$emit("item-add", value);
      this.emitValue();
    },

    removeTag(index) {
      const removedItem = this.selectedTags.splice(index, 1)[0];
      this.transform && this.value.splice(index, 1);
      this.$emit("item-remove", removedItem);
      this.emitValue();
    },

    emitValue() {
      this.$emit("update:modelValue", this.transform ? this.value : this.selectedTags);
    },

    onEnter(event) {
      event.preventDefault();

      const isSelected = this.selectedTags.findIndex((itemText) => itemText === this.entry) > -1;

      if (this.entry === "" || isSelected) {
        return;
      }

      const tag = this.suggestions?.find((suggestion) =>
        suggestion === this.entry || suggestion.name === this.entry
      ) ?? null;

      if (tag) {
        this.pushTag(tag);
      }
      else if (!tag && !this.selectedTags.includes(this.entry)) {
        this.pushTag(this.entry)
      }

      this.entry = "";
    },

    removeLastTag() {
      if (this.entry.length === 0) {
        this.removeTag(this.selectedTags.length - 1);
      }
    },

    onInput() {
      this.isTyping = true;
    },

    onSelect($event) {
      this.pushTag($event);
      this.entry = "";
    },

    onPaste(event) {
      let pastedText = (event.clipboardData || window.clipboardData).getData("text");
      let tagsToCreate = pastedText.match(/(?<=-\t).+/gm);

      if (tagsToCreate) {
        tagsToCreate.forEach(tagName => this.pushTag({ tagName }));
        event.preventDefault();
      }
      else if (this.characterLimit && pastedText.length > this.characterLimit) {
        $(event.target).tooltip('show');
        event.preventDefault();
      }
    },

    resize(input) {
      input.style.height = input.scrollHeight + "px";
    },

    focus() {
      this.isFocused = true;
      this.htmlInputElement.focus();
    },

    onFocusOut({ target: inputElement }) {
      this.isFocused = false;
      this.isTyping = false;
      $(inputElement).tooltip('hide');
    },

    handleWhitespace(event) {
      (event.target.value.slice(-1) === ' ' || event.target.value.length === this.characterLimit) && event.code === "Space" && event.preventDefault();
    },

    handlecharacterLimit(event) {
      const { target: inputElement, code } = event;

      if (inputElement.value.length >= this.characterLimit && !["Enter", "Backspace"].includes(code)
      ) {
        $(inputElement).tooltip('show');
        event.preventDefault();
      } else {
        $(inputElement).tooltip('hide');
      }
    },

    initCharacterLimitTooltip() {
      $(this.htmlInputElement).tooltip({
        title: "Maximum characters reached (max. 45)",
        trigger: "manual",
      });
    }
  },

  mounted() {
    this.selectedTags = this.modelValue;

    this.htmlInputElement = this.$el.querySelector('input[type="text"]');
    this.initCharacterLimitTooltip();
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Geologica');

.tag-input {
  cursor: text;
}

.tag-input input {
  width: v-bind('(entry.length + 3) + "ch"');
  font-family: Geologica, sans-serif;
}

.form-control:focus-within {
  box-shadow: 0 0 0 1px #6366f1;
  border-color: #4f46e5;
}
</style>
