import axios from '@/api';

const state = () => ({
    all: null
})

// getters
const getters = {
    getFormatByName: (state) => (name) => {
        const format = state.all.find(format => format.name.toLowerCase() === name.toLowerCase());
        return format;
    },
    getFormatIRI: () => (name) => {
        return getters.getFormatByName(name)['@id'];
    }
}

// actions
const actions = {
    getAllFormats({ commit }) {
        axios.get("/formats").then((res) => {
            commit('setFormats', res.data["hydra:member"]);
        })
    }
}

// mutations
const mutations = {
    setFormats(state, formats) {
        state.all = formats;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}