import store from "@/store"
import router from "@/router"

export default (instance) => {
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if ([401].includes(error.response.status)) {
                store.commit("user/clearUserData");
                router.push("/auth");
            }
            return Promise.reject(error);
        }
    );
}