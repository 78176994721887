<template>
  <ul>
    <slot name="list-prepend"></slot>
    <li
      v-for="(item, itemIndex) in data"
      :key="itemIndex"
      :class="liClass"
      :style="liStyle"
    >
      <slot name="list-item" v-bind="{ item, itemIndex }">
        {{ item }}
      </slot>
    </li>
    <slot name="list-append"></slot>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    liListeners: Object,
    liClass: String,
    liStyle: String,
  },
};
</script>