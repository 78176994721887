<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("user/authenticate");
  },
};
</script>