<template>
  <div class="row">
    <AppLink
      v-for="format in formats"
      :to="{
        name: 'New campaign',
        params: {
          name: snakeCase(format.name),
          adFormatName: format.name,
          adFormatId: format.id,
        },
      }"
      :key="format.id"
      class="col-md-6 col-lg-3 col-xlg-3"
    >
      <AdFormatCard :ad-format="format" />
    </AppLink>
  </div>
</template>

<script>
import AppLink from "@/components/AppLink.vue";
import AdFormatCard from "../components/AdFormatCard.vue";
import { mapState } from "vuex";
import { snakeCase } from "lodash-es";

export default {
  components: { AppLink, AdFormatCard },
  computed: mapState({
    formats: (state) => state.formats.all,
  }),
  mounted() {
    this.$store.dispatch("formats/getAllFormats");
  },
  methods: {
    snakeCase,
  },
};
</script>